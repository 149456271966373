




















import { Vue, Component } from "vue-property-decorator";
import { cancellationAccount } from "@/api";
import { State } from "vuex-class";
@Component({})
export default class ReportHead extends Vue {
  @State(state => JSON.parse(state.userInfo))
  userInfo!: any;
  mounted() {
    console.log(this.userInfo);
  }

  async currentClick() {
    try {
      await cancellationAccount();
      this.$message.success("注销成功");
      this.$router.push("/");
    } catch (error) {
      this.$message.error("注销失败");
    }
  }
}
